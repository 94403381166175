import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import React from 'react';
import { toByteString } from 'src/common/utils/numberFormatter/index';

import styles from './styles.module.scss';

const PlanSummaryPanel = (props) => {
  const { currentOrgLoaded, plans } = props;
  const isPresent = Object.keys(plans ?? {}).length >= 1;
  const formatMoney = (amount) => `$${parseFloat(amount).toFixed(2)}`;

  if (!currentOrgLoaded) return <Panel header="Data plans">Loading...</Panel>;

  return (
    <Panel header="Data plans">
      {isPresent ? (
        <div className={styles.plans_table}>
          <table>
            <thead>
              <tr>
                <th>Group ID</th>
                <th>Plan ID</th>
                <th>Plan name</th>
                <th>Data</th>
                <th>SMS</th>
                <th>Overage</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(plans).map((plan) => (
                <tr key={plan.id}>
                  <td>{plan.groupid}</td>
                  <td>{plan.id}</td>
                  <td>{plan.name}</td>
                  <td>{toByteString(plan.data)}</td>
                  <td>{plan?.zones ? formatMoney(Object.values(plan?.zones)[0].sms) : '-'}</td>
                  <td>{plan?.zones ? formatMoney(Object.values(plan?.zones)[0].overage) : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.no_plans_result}>
          <Icon name="CircleAlert" size="large" />
          <div className={styles.no_plans_text}>There are no custom plans to display.</div>
        </div>
      )}
    </Panel>
  );
};

export default PlanSummaryPanel;

import React from 'react';
import { Panel } from '@holokit/core';
import styles from './styles.module.scss';

const ActiveReleaseFlagsPanel = ({ orgInfo, loading }) => {
  const { releaseFlags: orgFlags } = orgInfo;
  const orgFlagKeys = Object.values(orgFlags ?? {}) ?? [];
  orgFlagKeys.sort();

  return (
    <Panel title="Active release flags" isLoading={loading}>
      <ul className={styles.active_release_flags}>
        {orgFlagKeys.map((flag) => (
          <li key={flag} className={styles.release_flag}>
            {flag}
          </li>
        ))}
      </ul>
    </Panel>
  );
};

export default ActiveReleaseFlagsPanel;

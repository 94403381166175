import React from 'react';

import { Route, Switch } from 'react-router';

import AuthedMainLayout from 'components/layouts/AuthedMain';
import OrgSearch from './Search';
import styles from './styles.module.scss';

function Organizations() {
  return (
    <AuthedMainLayout
      className={styles.organizations__container}
      navOptions={[
        {
          text: 'Search by organization',
          pathname: '/workspace/organizations/search?by=org_details',
          searchType: 'org_details',
          isDefault: true,
        },
        {
          text: 'Search by SIM',
          pathname: '/workspace/organizations/search?by=sim_details',
          searchType: 'sim_details',
        },
      ]}
    >
      <Switch>
        <Route path="/workspace/organizations/search/:orgid" component={OrgSearch} />
        <Route path="/workspace/organizations/search" component={OrgSearch} />
      </Switch>
    </AuthedMainLayout>
  );
}

export default Organizations;

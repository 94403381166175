import React from 'react';
import { Button, Panel, InputGroup, FormGroup, Select, Icon } from '@holokit/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import * as orgActions from 'common/store/entities/orgs/actions';

import styles from './styles.module.scss';

const TERMS_OPTIONS = ['', '15', '30', '45'];
const MODE_OPTIONS = ['Standard', 'Custom'];
const schema = Yup.object().shape({
  terms: Yup.string().required(),
  stripeId: Yup.string().required(),
  mode: Yup.string().required(),
  approval: Yup.string().required(),
});

const EnablePostpayModal = ({ closeModal, enablePostpayForOrg, orgId, postPayError }) => (
  <Panel
    title="Enable Post Pay"
    classes={styles.panel}
    headerButtons={
      <div className={styles.close} onClick={closeModal}>
        <Icon name="Close">Close</Icon>
      </div>
    }
  >
    <Formik
      validationSchema={schema}
      onSubmit={(values) => {
        enablePostpayForOrg(orgId, values.terms, values.stripeId, values.mode);
      }}
      initialValues={{
        terms: '',
        stripeId: '',
        mode: 'Standard',
        approval: '',
      }}
    >
      {(props) => (
        <>
          <FormGroup label="Terms">
            <Select
              value={props.values.terms}
              onChange={(e) => {
                props.setFieldValue('terms', e.currentTarget.value);
              }}
              options={TERMS_OPTIONS}
            />
          </FormGroup>
          <FormGroup label="Stripe Customer ID">
            <InputGroup
              name="stripeId"
              id="stripeId"
              type="text"
              onChange={props.handleChange}
              value={props.values.stripeId}
              required
            />
            {postPayError?.error && (
              <div className={styles.errorText}>Please check the Stripe Customer ID.</div>
            )}
          </FormGroup>
          <FormGroup label="Mode">
            <Select
              value={props.values.mode}
              onChange={(e) => {
                props.setFieldValue('mode', e.currentTarget.value);
              }}
              options={MODE_OPTIONS}
            />
          </FormGroup>
          <FormGroup label="Finance Approval">
            <InputGroup
              name="approval"
              id="approval"
              type="text"
              onChange={props.handleChange}
              value={props.values.approval}
              required
            />
          </FormGroup>
          <div className={styles.buttons}>
            <Button
              onClick={props.handleSubmit}
              type="primary"
              buttonProps={{ type: 'submit' }}
              disabled={!props.isValid || !props.dirty}
            >
              Enable Post Pay
            </Button>
          </div>
        </>
      )}
    </Formik>
  </Panel>
);

const mapStateToProps = (state) => ({
  postPayError: state.ui.error.postPay,
});

const mapDispatchToProps = {
  enablePostpayForOrg: orgActions.enablePostpayForOrg,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnablePostpayModal);

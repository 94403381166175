import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { Icon, Panel } from '@holokit/core';
import { selectOrgPackage } from 'common/selectors/entities/orgs';
import { setOrgPackage } from 'common/store/entities/orgs/actions';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

const EnableOrChangeOrgPlan = ({
  closeModal,
  hasPackage = false,
  orgId,
  packageId,
  packageAssignmentId,
  assignedPrice,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const orgPackage = useSelector(selectOrgPackage(orgId));

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        setOrgPackage({
          orgid: orgId,
          packageId,
          assignedPrice,
          ...(hasPackage ? { packageAssignmentId } : {}),
        })
      );

      closeModal();
      return false;
    },
    [dispatch, orgId, packageId, assignedPrice, hasPackage, packageAssignmentId, closeModal]
  );

  const handleClose = useCallback(() => {
    closeModal();
    onCancel();
  }, [closeModal, onCancel]);

  return (
    <Panel
      title={hasPackage ? 'Change plan?' : 'Enable access to new plans?'}
      classes={styles.panel}
      headerButtons={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={styles.close} onClick={handleClose}>
          <Icon name="Close">Close</Icon>
        </div>
      }
    >
      <Form
        onSubmit={onSubmit}
        footerActions={
          <>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="secondary" loading={!orgPackage?.loaded}>
              {hasPackage ? 'Change plan' : 'Enable access'}
            </Button>
          </>
        }
      >
        {hasPackage ? (
          <p>
            Features must be manually added to match the selected plan. Please make sure this
            matches the customer&apos;s MSA if they have one.
          </p>
        ) : (
          <>
            <p>
              Features must be manually added to match the selected plan. Please make sure this
              matches the customer&apos;s MSA if they have one.
            </p>

            <p>This action cannot be undone.</p>
          </>
        )}
      </Form>
    </Panel>
  );
};

export default EnableOrChangeOrgPlan;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { InputGroup, Button, Panel } from '@holokit/core';
import { pickBy } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import SearchedOrgsList from 'components/common/lists/SearchedOrgsList';
import * as orgActions from 'common/store/entities/orgs/actions';
import * as orgActionTypes from 'common/store/entities/orgs/actionTypes';
import * as userActions from 'common/store/entities/users/actions';
import * as userActionTypes from 'common/store/entities/users/actionTypes';
import * as errorActions from 'common/store/ui/errors/actions';

import styles from './styles.module.scss';

const validationSchema = Yup.object().shape({
  orgid: Yup.number(),
  orgname: Yup.string(),
  email: Yup.string(),
});

const OrgForm = ({
  clearSearchResults,
  handleViewClick,
  searchData,
  searchOrgs,
  searchUserOrgsByEmail,
  buttonContent,
  searchOrgsLoading,
  searchOrgsByEmailLoading,
  searchOrgError,
  clearOrgSearchState,
}) => {
  useEffect(
    () => () => {
      clearSearchResults();
      clearOrgSearchState();
    },
    []
  );

  const formik = useFormik({
    initialValues: {
      orgid: '',
      orgname: '',
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const value = pickBy(values, (val) => !!val);

      if (value.orgid) {
        searchOrgs(values.orgid, true);
      } else if (value.orgname) {
        searchOrgs(values.orgname);
      } else if (value.email) {
        searchUserOrgsByEmail(values.email);
      }
    },
  });

  const advanceOrgsList = () => {
    const { orgname, orgid, email } = formik.values;
    if (email) {
      searchUserOrgsByEmail(email, searchData.limit + 10);
    } else {
      const isQueryById = !!orgid;
      searchOrgs(orgname, isQueryById, searchData.limit + 10);
    }
  };

  const handleOrgClick = (e) => {
    const { orgId } = e.currentTarget.dataset;
    handleViewClick(orgId);
  };

  return (
    <Panel title="Find an organization" isLoading={searchOrgsLoading || searchOrgsByEmailLoading}>
      <div className={styles.formContainer}>
        <div className={styles.inputContainer}>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <label htmlFor="orgid" className={styles.input_label}>
                By org ID
              </label>
              <div className={styles.input_field}>
                <InputGroup
                  id="orgid"
                  name="orgid"
                  onChange={formik.handleChange}
                  placeholder="Org ID"
                  type="text"
                  value={formik.values.orgid}
                  disabled={formik.values.orgname || formik.values.email}
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="orgname" className={styles.input_label}>
                By org name
              </label>
              <div className={styles.input_field}>
                <InputGroup
                  name="orgname"
                  id="orgname"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.orgname}
                  placeholder="Org name"
                  disabled={formik.values.orgid || formik.values.email}
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="email" className={styles.input_label}>
                By user email
              </label>
              <div className={styles.input_field}>
                <InputGroup
                  name="email"
                  id="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Email"
                  disabled={formik.values.orgname || formik.values.orgid}
                />
              </div>
            </div>
            <Button
              type="primary"
              buttonProps={{ type: 'submit' }}
              classes={styles.buttonClassname}
              disabled={!formik.isValid || !formik.dirty}
            >
              Search
            </Button>
          </form>
        </div>
        <SearchedOrgsList
          orgsList={searchData.searched}
          continues={searchData.dataContinues}
          success={searchData.success}
          onPageAdvance={advanceOrgsList}
          onOrgClick={handleOrgClick}
          selectButtonContent={buttonContent}
          clearSearchResults={clearSearchResults}
          searchOrgError={searchOrgError}
          showPersonalOrgsAsOwnList
        />
      </div>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  searchData: state.entities.orgs.temp,
  searchOrgError: state.ui.error.searchOrg,
  searchOrgsLoading: state.ui.loading[orgActionTypes.SEARCH_ORG_REQUEST],
  searchOrgsByEmailLoading: state.ui.loading[userActionTypes.SEARCH_USER_BY_EMAIL_REQUEST],
});

const mapDispatchToProps = {
  searchOrgs: orgActions.searchOrgs,
  clearSearchResults: orgActions.clearSearchResults,
  searchUserOrgsByEmail: userActions.searchUserOrgsByEmail,
  clearOrgSearchState: errorActions.clearOrgSearchState,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgForm);

import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { Icon } from '@hologram-dimension/icon';
import { Panel } from '@hologram-dimension/panel';
import { RadioField } from '@hologram-dimension/radio-field';
import { TextInput } from '@hologram-dimension/text-input';
import { TextInputField } from '@hologram-dimension/text-input-field';
import * as groupActions from 'common/store/entities/groups/actions';
import * as uiActions from 'common/store/ui/entities/actions';
import { throttle } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styles from './styles.module.scss';

class GroupPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupName: '',
      groupType: null,
      groupQuery: '',
    };

    this.submitQuery = throttle(this.submitQuery, 1000);
  }

  onGroupNameChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSearch = (e) => {
    const { value } = e.target;
    this.setState({ groupQuery: value });
    this.submitQuery();
  };

  onCheckboxClick = (e) => {
    const { value } = e.target;
    this.setState({ groupType: value });
  };

  onViewClick = (groupId) => {
    const { getGroupMembers, setActiveGroup, removeOrg } = this.props;
    getGroupMembers(groupId);
    setActiveGroup(groupId);
    removeOrg();
  };

  onAddClick = () => {
    const { onComplete, group } = this.props;
    onComplete(false, group.name, group.id);
  };

  onGroupCreate = () => {
    const { onComplete } = this.props;
    const { groupName } = this.state;
    onComplete(true, groupName);
  };

  submitQuery = () => {
    const { searchGroups } = this.props;
    const { groupQuery } = this.state;
    if (groupQuery !== '') {
      searchGroups(groupQuery);
    }
  };

  render() {
    const { groupName, groupQuery, groupType } = this.state;
    const { group, orgName, searchedGroups } = this.props;

    const isExisting = groupType === 'existing';
    const isNew = groupType === 'new';

    return (
      <Panel header="Groups">
        <div className={styles.groups__container}>
          <div className={styles.groups__item}>
            <RadioField
              name="newOrExistingGroup"
              label="New or existing group"
              options={[
                { label: 'New', value: 'new' },
                { label: 'Existing', value: 'existing' },
              ]}
              controlsLayoutDirection="row"
              layoutDirection="column"
              onChange={this.onCheckboxClick}
            />
            {isNew && (
              <Form
                footerActions={<Button onClick={this.onGroupCreate}>Create group</Button>}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.onGroupCreate();
                  return false;
                }}
              >
                <TextInputField
                  placeholder="Group name"
                  name="groupName"
                  defaultValue={groupName}
                  onChange={this.onGroupNameChange}
                />
              </Form>
            )}
            {isExisting && (
              <div className={styles.existingWrapper}>
                <div className={styles.searchField}>
                  <TextInput
                    label="Group name"
                    name="groupQuery"
                    type="text"
                    placeholder="Group name"
                    defaultValue={groupQuery}
                    onChange={this.onSearch}
                  />
                  <Icon name="Search" />
                </div>
                <table className={styles.resultsTable}>
                  <tbody>
                    {searchedGroups.map((tempGroup) => (
                      <tr key={tempGroup.id}>
                        <td className={styles.nameData}>
                          {tempGroup.name} ({tempGroup.id})
                        </td>
                        <td>
                          <Button size="small" onClick={() => this.onViewClick(tempGroup.id)}>
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {isExisting && group && (
            <div className={styles.groups__item} data-jest="org-list">
              <h2 className={styles.groupSubHeader}>{group.name}</h2>
              <p>Included Orgs:</p>
              <ul className={styles.form__results}>
                {group.users?.map((org) => (
                  <li key={org.id} className={styles.form__results__item}>
                    {org.name} ({org.id})
                  </li>
                ))}
                {orgName && <li className={styles.addedOrg}>+ {orgName}</li>}
              </ul>
              <Button variant="secondary" size="small" onClick={this.onAddClick}>
                Use this group
              </Button>
            </div>
          )}
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedGroupId = state.ui.entities.selections.activeGroup;
  const group = state.entities.groups.byId[selectedGroupId];

  return {
    group,
    searchedGroups: state.entities.groups.temp.searched,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroupMembers: groupActions.getGroupMembers,
      searchGroups: groupActions.searchGroups,
      setActiveGroup: uiActions.setActiveGroupId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupPanel);

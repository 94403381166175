import React from 'react';
import { Panel } from '@holokit/core';

import styles from './styles.module.scss';

const CollaboratorsPanel = React.memo(({ org }) => {
  const users = org?.users?.sort((a, b) => a.first > b.first);
  return (
    <Panel title="Users" bodyClasses={styles.panel}>
      {users &&
        users.map((user) => (
          <div className={styles.userItem} key={user.id}>
            <div>
              {user.first} {user.last} - {user.id}
            </div>
            (
            <a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
              {user.email}
            </a>
            )
          </div>
        ))}
    </Panel>
  );
});

export default CollaboratorsPanel;

import { Link, useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import { Button, Icon, Panel } from '@holokit/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as releaseFlagActions from 'common/store/entities/releaseflags/actions';

import { getAllReleaseFlags, removeReleaseFlagFromPublic, makeReleaseFlagPublic } from 'common/api';

import styles from './styles.module.scss';

function ReleaseFlag(props) {
  const emptyFlagState = {
    name: '',
    isPublic: null,
    organizations: [],
  };

  const [currentFlag, setCurrentFlag] = useState(emptyFlagState);
  const [isLoading, setIsLoading] = useState(false);

  const { flagName } = useParams();

  const setNewFlag = (isPublic) => {
    if (isPublic) {
      setCurrentFlag({
        ...currentFlag,
        isPublic: false,
      });
    } else {
      setCurrentFlag({
        ...currentFlag,
        isPublic: true,
      });
    }
  };

  const getPanelMessage = () => {
    if (currentFlag && flagName) {
      const numOfOrgs = currentFlag.organizations.length;
      if (currentFlag.isPublic) {
        return 'This feature is available to all organizations.';
      }
      if (numOfOrgs === 0) {
        return 'There are no organizations with this flag turned on.';
      }
      if (numOfOrgs > 30) {
        return `${numOfOrgs} orgs have this release flag turned on.`;
      }
      return (
        <div>
          <h3 className={styles.title}>Organizations with Flag</h3>
          <ul className={styles.orgContainer}>
            {currentFlag.organizations.map((org) => (
              <li className={styles.orgItem}>
                <Link to={`/organizations/?orgid=${org.id}`}>
                  <div className={styles.orgItemsLink}>
                    {org.name} ({org.id})
                  </div>
                </Link>
                <Icon name="Arrow--northeast" />
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return 'No flag selected.';
  };

  useEffect(() => {
    if (flagName) {
      setIsLoading(true);
      getAllReleaseFlags().then((res) => {
        const flags = Object.keys(res?.data?.data ?? {});
        flags.forEach((flag) => {
          if (flag === flagName) {
            setCurrentFlag({
              name: flag,
              isPublic: Number(res.data.data[flag].public) === 1,
              organizations: res.data.data[flag].organizations || [],
            });
            setIsLoading(false);
          }
        });
      });
    }
  }, [flagName]);

  const handleClick = (e, flagToUpdate, isPublic) => {
    e.preventDefault();
    if (isPublic) {
      removeReleaseFlagFromPublic(flagToUpdate);
    } else {
      makeReleaseFlagPublic(flagToUpdate);
    }
    setNewFlag(isPublic);
  };

  const handleRelease = (e, flagToUpdate, isRollingOut) => {
    e.preventDefault();
    const { updateFlagsForOrgs } = props;
    updateFlagsForOrgs(flagToUpdate, isRollingOut);
  };

  return (
    <div className={styles.ReleaseFlag}>
      <div className={styles.outerContainer}>
        <Link to="/workspace/releaseflags">
          <Button iconLeft="Arrow--west" type="primary">
            All Flags
          </Button>
        </Link>
        <Panel
          classes={styles.panel}
          title={currentFlag.name}
          isLoading={isLoading}
          headerButtons={
            <div style={{ marginLeft: 'auto', paddingLeft: '20px' }}>
              {currentFlag.organizations.length > 0 && (
                <Button
                  type="destructiveMajor"
                  onClick={(e) => handleRelease(e, currentFlag.name, false)}
                >
                  Rollback 25%
                </Button>
              )}
              <Button type="primary" onClick={(e) => handleRelease(e, currentFlag.name, true)}>
                Rollout 25%
              </Button>
              <Button
                type={currentFlag.isPublic ? 'destructiveMajor' : 'primary'}
                onClick={(e) => handleClick(e, currentFlag.name, currentFlag.isPublic)}
              >
                {currentFlag.isPublic ? 'Make Private' : 'Make Global'}
              </Button>
            </div>
          }
        >
          {getPanelMessage()}
        </Panel>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFlagsForOrgs: releaseFlagActions.updateFlagsForOrgs,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ReleaseFlag);

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Panel, Button, Icon, FormGroup, InputGroup, Select } from '@holokit/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as orgActions from 'common/store/entities/orgs/actions';
import { modalNames } from 'components/modals/ModalInstances';
import { TRANSACTION_TYPES, TRANSACTION_TYPE_NAMES } from 'common/utils/constants';

import styles from './styles.module.scss';

const TRANSACTION_CHARGE_TYPE = {
  'Promo Credit': 'Promo Credit',
  Account: 'Account',
  Charge: 'Charge',
};

const validationSchema = Yup.object().shape({
  amount: Yup.number().required().positive(),
  transactionType: Yup.string().required(),
  description: Yup.string().required(),
});

const AddBalanceModal = ({
  closeModal,
  openModal,
  addAccountBalanceForOrg,
  addPromoCreditForOrg,
  addChargeBalanceForOrg,
  orgId,
  chargeType,
}) => {
  const formik = useFormik({
    initialValues: {
      amount: '',
      transactionType: chargeType === 'Promo Credit' ? 'PC' : '',
      description: '',
    },
    validationSchema,
    onSubmit: (values) => {
      openConfirmationModal(values);
    },
  });

  const updateTransactionType = (e) => {
    formik.setFieldValue('transactionType', e.target.value);
  };

  const openConfirmationModal = (values) => {
    const { amount, transactionType, description } = values;

    const modalProps = {
      title: `Add ${TRANSACTION_CHARGE_TYPE[chargeType]} Balance?`,
      text: `Are you sure you want to add $${parseFloat(amount).toFixed(2)} for ${description}?`,
      onConfirm: () => {
        if (chargeType === TRANSACTION_CHARGE_TYPE.Account) {
          addAccountBalanceForOrg(orgId, amount, transactionType, description);
        } else if (chargeType === TRANSACTION_CHARGE_TYPE['Promo Credit']) {
          addPromoCreditForOrg(orgId, amount, chargeType, description);
        } else if (chargeType === TRANSACTION_CHARGE_TYPE.Charge) {
          addChargeBalanceForOrg(orgId, amount, transactionType, description);
        }
      },
    };
    openModal(modalNames.confirmation, modalProps);
  };

  const buildOptions = useMemo(() => {
    const validChargeTypes = TRANSACTION_TYPES[chargeType];
    return validChargeTypes.map((option) => ({
      name: option,
      value: TRANSACTION_TYPE_NAMES[option],
    }));
  }, []);

  return (
    <Panel
      title={`Add ${TRANSACTION_CHARGE_TYPE[chargeType]} Balance`}
      classes={styles.panel}
      headerButtons={
        <div className={styles.close} onClick={closeModal}>
          <Icon name="Close">Close</Icon>
        </div>
      }
    >
      <div className={styles.modalContainer}>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup label="Amount" labelFor="amount">
            <InputGroup
              name="amount"
              id="amount"
              type="number"
              placeholder="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              status={formik.errors.amount && 'warning'}
            />
            {formik.errors.amount && <div className={styles.errorText}>{formik.errors.amount}</div>}
          </FormGroup>
          {chargeType !== TRANSACTION_CHARGE_TYPE['Promo Credit'] && (
            <FormGroup label="Transaction Type" labelFor="transactionType">
              <Select
                placeholder="Select a Transaction Type"
                id="transactionType"
                options={buildOptions}
                value={formik.values.transactionType}
                onChange={updateTransactionType}
              />
            </FormGroup>
          )}
          <FormGroup
            label="Description"
            labelFor="description"
            helpText="This description will be reflected to the customer on the Dashboard."
          >
            <InputGroup
              name="description"
              id="description"
              type="text"
              placeholder="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Button
              type="primary"
              buttonProps={{ type: 'submit' }}
              disabled={!formik.isValid || !formik.dirty}
            >
              {`Add ${TRANSACTION_CHARGE_TYPE[chargeType]} Balance`}
            </Button>
          </FormGroup>
        </form>
      </div>
    </Panel>
  );
};

const mapDispatchToProps = {
  addAccountBalanceForOrg: orgActions.addAccountBalanceForOrg,
  addPromoCreditForOrg: orgActions.addPromoCreditForOrg,
  addChargeBalanceForOrg: orgActions.addChargeBalanceForOrg,
};

export default connect(null, mapDispatchToProps)(AddBalanceModal);

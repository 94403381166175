/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */

import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { countDecimalDigits } from 'common/utils/decimalCounter';
import ErrorBoundary from 'components/common/ErrorBoundary';
import React from 'react';
import styles from './ZoneForm.module.scss';

class ZoneForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isAmountValid: true,
      isSmsValid: true,
      isOverageValid: true,
    };
  }

  handleZoneNameChange = (e) => {
    const { onUpdateZone } = this.props;
    onUpdateZone('type', e.target.value);
  };

  handleZoneParamChange = (e) => {
    const { onUpdateZone } = this.props;
    const { value, name } = e.target;

    const expression = /^\d*\W?\d*$/;

    if (expression.test(value) || value === '') {
      const map = {
        amount: 'isAmountValid',
        sms: 'isSmsValid',
        overage: 'isOverageValid',
      };
      if (countDecimalDigits(value) <= 6) {
        this.setState({ [map[name]]: true });
      } else {
        this.setState({ [map[name]]: false });
      }
      onUpdateZone(name, value);
    }
  };

  render() {
    const { isEditPage, zone } = this.props;
    const { isAmountValid, isSmsValid, isOverageValid } = this.state;

    return (
      <ErrorBoundary>
        <div className={styles.container}>
          <div className={styles.zone_card_container}>
            <div className={styles.zone_card}>
              {(!isAmountValid || !isSmsValid || !isOverageValid) && (
                <h3 className={styles.error}>Values only accepted to six decimal places</h3>
              )}
              <Form>
                <TextInputField
                  label="Zone name"
                  defaultValue={zone.type}
                  inputProps={{
                    onChange: this.handleZoneNameChange,
                  }}
                  disabled={isEditPage}
                  required
                />
                <TextInputField
                  label="MRC / device"
                  defaultValue={zone.amount}
                  name="amount"
                  inputProps={{ onChange: this.handleZoneParamChange }}
                  type="number"
                  required
                  invalid={!isAmountValid}
                />
                <TextInputField
                  label="Cost / SMS"
                  defaultValue={zone.sms}
                  name="sms"
                  inputProps={{ onChange: this.handleZoneParamChange }}
                  type="number"
                  required
                  invalid={!isSmsValid}
                />
                <TextInputField
                  label="Cost / overage MB"
                  defaultValue={zone.overage}
                  name="overage"
                  inputProps={{ onChange: this.handleZoneParamChange }}
                  type="number"
                  required
                  invalid={!isOverageValid}
                />
              </Form>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default ZoneForm;

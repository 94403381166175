import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getOrgReleaseFlags } from 'common/store/entities/orgs/actions';
import { updateReleaseFlagForOrg, updatePreflightDurationForOrg } from 'src/common/api';
import Package from './Package';
import styles from './styles.module.scss';
import Preflight from './Preflight';

const groupCallouts = ({ label, settledRequests, flags }) =>
  settledRequests.reduce(
    (callouts, { status, value }, index) => {
      if (status === 'fulfilled') {
        const { flagName, removed } = value;
        callouts.successfulCallouts.push(
          `${removed ? 'Removed' : 'Added'} access to “${label}” (${flagName})`
        );
      } else {
        const flagName = flags[index];
        callouts.errorCallouts.push(`Unable to save changes to “${label}” (${flagName})`);
      }
      return callouts;
    },
    { successfulCallouts: [], errorCallouts: [] }
  );

const Features = ({
  featureFlagList,
  orgId,
  orgFlags,
  setPanelLoading,
  addSuccessCallouts,
  addErrorCallouts,
  setReleaseFlagsLoading,
  preflightTimeout,
}) => {
  const dispatch = useDispatch();
  const updateFlagForOrg = useCallback(
    (flagName, removeFlag) =>
      updateReleaseFlagForOrg(orgId, flagName, removeFlag).then(() =>
        Promise.resolve({ flagName, removed: removeFlag })
      ),
    [orgId]
  );
  const updateDurationForOrg = useCallback(
    (duration) =>
      updatePreflightDurationForOrg(orgId, duration).then(() =>
        Promise.resolve({ orgId, duration })
      ),
    [orgId]
  );

  const onCheckboxClick = useCallback(
    async ({ label, flags, disable }) => {
      setPanelLoading(true);
      setReleaseFlagsLoading(true);

      try {
        const settledRequests = await Promise.allSettled([
          ...flags.map((flagName) => updateFlagForOrg(flagName, disable)),
        ]);

        const { successfulCallouts, errorCallouts } = groupCallouts({
          label,
          settledRequests,
          flags,
        });

        if (errorCallouts.length > 0) {
          addErrorCallouts(errorCallouts);
        }

        if (successfulCallouts.length > 0) {
          addSuccessCallouts(successfulCallouts);

          // With redux-saga this won't actually work async, sadly
          // TODO: handle this more idiomatically so we don't get the weird in-between state
          // when checking the checkboxes
          await dispatch(getOrgReleaseFlags(orgId));
        }
      } catch {
        addErrorCallouts(['Unable to save changes']);
      } finally {
        setPanelLoading(false);
        setReleaseFlagsLoading(false);
      }
    },
    [
      addSuccessCallouts,
      addErrorCallouts,
      dispatch,
      orgId,
      setPanelLoading,
      setReleaseFlagsLoading,
      updateFlagForOrg,
    ]
  );

  const onPreflightDurationChange = useCallback(
    async ({ duration }) => {
      setPanelLoading(true);
      setReleaseFlagsLoading(true);

      try {
        await updateDurationForOrg(duration);
        addSuccessCallouts([`Changed preflight duration to ${duration}`]);
      } catch {
        addErrorCallouts(['Unable to save changes']);
      } finally {
        setPanelLoading(false);
        setReleaseFlagsLoading(false);
      }
    },
    [
      addSuccessCallouts,
      addErrorCallouts,
      setPanelLoading,
      setReleaseFlagsLoading,
      updateDurationForOrg,
    ]
  );

  return (
    <div>
      <div className={styles.gating_flag_container}>
        <Preflight
          preflightFlags={featureFlagList.predeployment}
          orgFlags={orgFlags}
          onFlagChange={onCheckboxClick}
          onPreflightDurationChange={onPreflightDurationChange}
          preflightTimeout={preflightTimeout}
        />
        <Package
          name="Home (Premium only)"
          features={featureFlagList.home}
          orgFlags={orgFlags}
          onCheckboxClick={onCheckboxClick}
          setPanelLoading={setPanelLoading}
          description=""
        />
      </div>
    </div>
  );
};

export default Features;

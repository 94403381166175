import React from 'react';
import { ToastContainer } from 'react-toastify';

import Nav from 'components/common/Nav/MainNavBar';
import { CloseButton } from 'src/components/common/Toast';

import styles from './Authedmain.module.scss';

const Layout = ({ children, navOptions }) => (
  <div className={styles.container}>
    <div className={styles.contentContainer}>
      <Nav options={navOptions} />
      <ToastContainer
        position="top-right"
        closeButton={<CloseButton />}
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
      />
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  </div>
);

export default Layout;

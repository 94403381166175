import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ToastContainer } from 'react-toastify';

import BaseLayout from 'components/layouts/Base';
import LoginForm from 'components/forms/LoginForm';
import { ComplexIcon, Panel } from '@holokit/core';
import MfaLoginModal from 'components/modals/ModalInstances/MfaLoginModal';
import { CloseButton } from 'src/components/common/Toast';

import styles from './styles.module.scss';

const Login = ({ user, isMfaFlow, showModal, userIsAuthed }) => (
  <>
    {userIsAuthed ? (
      <Redirect to="/workspace/organizations/search" />
    ) : (
      <BaseLayout>
        <img
          className={styles.image}
          src="https://cdn1.hologram.io/hologram_core/paper.jpg"
          alt=""
        />
        <div className={styles.overlay} />
        <div className={styles.header}>
          <ComplexIcon name="rainbow-logo-text" classes={styles.icon} />
        </div>
        <ToastContainer
          position="top-right"
          type="warning"
          closeButton={<CloseButton />}
          autoClose={10000}
          hideProgressBar
          closeOnClick
        />
        {isMfaFlow && showModal ? (
          <MfaLoginModal />
        ) : (
          <Panel double bodyClasses={styles.panelBody}>
            Hologram Core
            <div className={styles.loginForm}>
              {user.loginFailed ? <span>Failed login</span> : null}
              <LoginForm />
            </div>
          </Panel>
        )}
      </BaseLayout>
    )}
  </>
);

const mapStateToProps = (state) => ({
  user: state.user,
  isMfaFlow: state.user?.mfa?.flow,
  showModal: state.user?.mfa?.showModal,
  userIsAuthed: state.user.userIsAuthed,
});

export default connect(mapStateToProps)(Login);

import { SelectField } from '@hologram-dimension/select-field';
import { selectOrgPackage } from 'common/selectors/entities/orgs';
import { selectAllPackages, selectLoadingAllPackages } from 'common/selectors/state/config';
import { modalNames } from 'components/modals/ModalInstances';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

// The custom amount code was last available in 1.0.0 (2ea25cd8d2725e90701ad9f76a1690d43578cfd2), should you need to restore it

const PlanSelect = ({ orgId, openModal, setPanelLoading }) => {
  const allPackages = useSelector(selectAllPackages);
  const loadingAllPackages = useSelector(selectLoadingAllPackages);
  const orgPackage = useSelector(selectOrgPackage(orgId));

  const getDefaultPriceForPackage = useCallback(
    (id) =>
      id
        ? allPackages.find((pkg) => parseInt(pkg.id, 10) === parseInt(id, 10))?.default_price
        : null,
    [allPackages]
  );

  const {
    id: packageAssignmentId,
    package_id: selectedPackageId,
    loaded: loadedPackage,
  } = orgPackage ?? { loaded: false };

  const hasPackage = !!selectedPackageId;

  const dropdownItems = useMemo(
    () => [
      { disabled: true, label: 'None', value: -1 },
      ...allPackages.map(({ id, name }) => ({ value: id, label: name })),
    ],
    [allPackages]
  );

  useEffect(() => {
    setPanelLoading(!loadedPackage);
  }, [loadedPackage, setPanelLoading]);

  const onPackageChange = useCallback(
    (event) => {
      const packageId = event?.target?.value;

      openModal(modalNames.enableOrChangeOrgPlan, {
        hasPackage,
        orgId,
        packageId,
        assignedPrice: getDefaultPriceForPackage(packageId),
        packageAssignmentId,
      });
    },
    [getDefaultPriceForPackage, hasPackage, openModal, orgId, packageAssignmentId]
  );

  return (
    <div className={styles.package_form_container}>
      {!loadingAllPackages && loadedPackage && (
        <SelectField
          className={styles.selected_package_dropdown}
          label="Selected plan"
          id="selected-package"
          layoutDirection="row"
          onChange={onPackageChange}
          defaultValue={hasPackage ? selectedPackageId : -1}
          options={dropdownItems}
        />
      )}
    </div>
  );
};

export default PlanSelect;

import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Checkbox } from '@holokit/core';
import styles from './styles.module.scss';

const FeatureRow = ({ label, checked = false, disabled = false, flags = [], onCheckboxClick }) => {
  const [checkboxLoading, setCheckboxLoading] = useState(false);

  const baseFlag = flags[0];

  const onClick = useCallback(() => {
    setCheckboxLoading(true);

    onCheckboxClick({
      label,
      flags,
      disable: checked,
    }).finally(() => {
      setCheckboxLoading(false);
    });
  }, [checked, flags, label, onCheckboxClick, setCheckboxLoading]);

  return (
    <div
      className={classnames(styles.flag_row, {
        [styles['flag_row--disabled']]: disabled,
      })}
    >
      <Checkbox
        className={styles.flag_row__checkbox}
        checked={checked}
        disabled={checkboxLoading || disabled}
        inputProps={{ id: baseFlag }}
        onChange={onClick}
      />
      <label htmlFor={baseFlag} className={styles.flag_row__label}>
        {label}
      </label>
    </div>
  );
};

export default FeatureRow;

import React from 'react';
import { Callout, Icon } from '@holokit/core';
import styles from './styles.module.scss';

const ResponseCallout = ({ text, calloutType, onClick }) => (
  <Callout
    text={text}
    type={calloutType}
    className={styles.response_callout}
    iconColor={Callout.THEME.COLOR}
    rightElement={
      <button onClick={onClick} type="button">
        <Icon
          name={Icon.names.CLOSE}
          size={Icon.sizes.MINOR}
          svgProps={{ style: { fill: '#80889e' } }}
        />
      </button>
    }
    defaultIcon
  />
);

export default ResponseCallout;

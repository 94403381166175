import { PLAN_ZONE_NAMES } from 'common/utils/constants';
import React from 'react';
import { connect } from 'react-redux';
import styles from './DetailsPanel.module.scss';

function DetailsPanel({ planData, group, orgName, regions }) {
  const getPlanZoneName = (backendName) =>
    Object.keys(PLAN_ZONE_NAMES).find((key) => PLAN_ZONE_NAMES[key] === backendName) ?? backendName;

  const getRegionName = () => {
    if (!regions) {
      return null;
    }
    const selectedRegion = regions.find((region) => region?.id?.toString() === planData.region);
    return selectedRegion?.display_name;
  };

  const { data, name, region, zones } = planData;
  const zoneDisplayName = getPlanZoneName(zones.type);
  const regionDisplayName = region ? getRegionName(region) : undefined;
  let orgNames = group?.users
    ? group.users
        .slice(0, 10)
        .map((groupUser) => groupUser.name)
        .join(', ')
    : orgName;

  if (group?.users?.length > 10) {
    orgNames += `... (${group.users.length - 10} more)`;
  }
  const planName = name || '(This Plan)';

  return (
    <div className={styles.container}>
      This will create a plan for the <span className={styles.text}>{group?.name}</span> group which
      contains the orgs
      <span className={styles.text}> {orgNames}. </span>
      <span className={styles.text}>{planName}</span>{' '}
      {regionDisplayName && (
        <>
          will be available in the <span className={styles.text}>{regionDisplayName}</span> region
          and{' '}
        </>
      )}
      will include <span className={styles.text}>{data}B</span> of prepaid data. It will encompass a{' '}
      <span className={styles.text}>{zoneDisplayName}</span> zone with{' '}
      <span className={styles.text}>${zones.amount}</span> MRC per device, and{' '}
      <span className={styles.text}>${zones.sms}</span> per SMS message sent. The overage cost is{' '}
      <span className={styles.text}>${zones.overage}</span> per MB. And uses carrier{' '}
      <span className={styles.text}>{Object.keys(zones.carriers ?? {})[0]}</span>, CSP{' '}
      <span className={styles.text}>
        {Object.values(zones.carriers ?? {}).map((carrier) => carrier.csp)[0]}
      </span>
      .
    </div>
  );
}

const mapStateToProps = (state) => ({
  regions: state.entities.plans.regions,
});

export default connect(mapStateToProps)(DetailsPanel);

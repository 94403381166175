import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Button, ComplexIcon, Icon, InputGroup, Panel, Callout } from '@holokit/core';
import * as userActions from 'common/store/user/actions';
import { Toast } from 'src/components/common/Toast';
import styles from './styles.module.scss';

const MfaLoginModal = ({
  authenticateTotp,
  resendTotp,
  clearMfaErrors,
  resetMfaFlow,
  email,
  error,
  isLoading,
}) => {
  const [code, setCode] = useState('');
  const inputRef = useRef(null);
  const [resendCopy, setResendCopy] = useState(false);
  const [disableButton, setDisabledButton] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setCode(value);
  };

  const renderEmail = () => {
    const at = email.indexOf('@');
    const domain = email.slice(at);
    const stars = Array(email.slice(0, at - 1).length)
      .fill('*')
      .join('');
    const firstLetter = email[0];

    return `${firstLetter}${stars}${domain}`;
  };

  const resetMfa = (e) => {
    e.preventDefault();
    resetMfaFlow();
  };

  const authenticateCode = (e) => {
    e.preventDefault();
    authenticateTotp(code);
  };

  const resendCode = () => {
    resendTotp();
    clearMfaErrors();
    setCode('');
    setResendCopy(true);
    toast(<Toast icon="Checkmark--single" headline="Verification code sent!" />, {
      position: toast.POSITION.TOP_RIGHT,
      className: classnames(styles.toastify__top_right, styles.toastify_content),
      autoClose: 10000,
      hideProgressBar: true,
    });
    setDisabledButton(true);
    setTimeout(() => {
      setDisabledButton(false);
    }, 10000);
  };

  const footer = (
    <div className={styles.MFA_Login_Modal__footer}>
      {resendCopy ? 'Still having trouble with your code?' : "Didn't receive a code?"}
      <button type="button" onClick={resendCode} disabled={disableButton}>
        Send new code{' '}
      </button>
      {resendCopy && (
        <>
          or{' '}
          <a
            href="https://support.hologram.io/hc/en-us/requests/new"
            target="_blank"
            rel="noreferrer"
          >
            contact support
          </a>
        </>
      )}
    </div>
  );

  return (
    <div className={styles.MFA_Login_Modal}>
      <div className={styles.MFA_Login_Modal__background}>
        <a href="/" onClick={resetMfa}>
          <div className={styles.MFA_Login_Modal__link}>
            <Icon
              name="Chevron--single--west"
              size="minor"
              svgProps={{ style: { fill: '#6606C6' } }}
            />{' '}
            <span>Back to login</span>
          </div>
        </a>
        <div className={styles.MFA_Login_Modal__content}>
          <ComplexIcon
            classes={styles.MFA_Login_Modal__logo_text_black}
            name="black-logo-black-text"
          />
          <Panel title="Verify it's you" footer={footer}>
            To complete your login, enter the 6-digit code sent to <strong>{renderEmail()}</strong>
            <p className={styles.MFA_Login_Modal__content_code}>Verification Code</p>
            <form>
              <InputGroup
                type="number"
                placeholder="123456"
                classes={styles.MFA_Login_Modal__content_input}
                onChange={handleOnChange}
                ref={inputRef}
                value={code}
                status={error ? 'warning' : null}
              />
              {error && (
                <Callout
                  type={Callout.TYPE.ERROR}
                  text={error}
                  iconColor={Callout.THEME.COLOR}
                  defaultIcon
                  className={styles.MFA_Login_Modal__content_error}
                />
              )}
              <Button
                type="primary"
                buttonProps={{ type: 'submit' }}
                onClick={authenticateCode}
                loading={isLoading}
              >
                Submit
              </Button>
            </form>
          </Panel>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.user.mfa.isLoading,
  isMfaFlow: state.user.mfa.flow,
  showModal: state.user.mfa.showModal,
  email: state?.user?.user?.email,
  error: state.user.mfa.error,
});

const mapDispatchToProps = (dispatch) => ({
  authenticateTotp: (totpCode) => dispatch(userActions.authenticateTotp(totpCode)),
  resendTotp: () => dispatch(userActions.resendTotp()),
  clearMfaErrors: () => dispatch(userActions.clearMfaErrors()),
  resetMfaFlow: () => dispatch(userActions.resetMfaFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MfaLoginModal);

import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import TierForm from 'components/plans/TierPanel/TierForm/index';
import React from 'react';
import styles from './TierPanel.module.scss';

class TierPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingNew: false,
    };
  }

  onRemoveUnsavedTier = () => {
    this.setState({ isAddingNew: false });
  };

  onTierDone = (tierInfo) => {
    const { onAddTier } = this.props;
    onAddTier(tierInfo);
    this.setState({ isAddingNew: false });
  };

  addTier = () => {
    this.setState({
      isAddingNew: true,
    });
  };

  updateTier = (tier, tierValues) => {
    const { updateTiers } = this.props;
    updateTiers(tier, tierValues);
  };

  render() {
    const { isAddingNew } = this.state;
    const { tiers, removeTier } = this.props;
    const currentTiers = Object.keys(tiers ?? {});

    return (
      <div>
        <Panel header="Tiers (optional)">
          <div className={styles.panel__container}>
            <div className={styles.panel__item}>
              <div className={styles.tier_form_container}>
                {Object.values(tiers).map((tierInfo) => (
                  <TierForm
                    tierInfo={tierInfo}
                    onAddTier={(values) => {
                      this.updateTier(tierInfo.tier, values);
                    }}
                    key={tierInfo.tier}
                    removeTier={removeTier}
                    currentTiers={currentTiers}
                  />
                ))}
                {isAddingNew && (
                  <TierForm
                    onAddTier={this.onTierDone}
                    removeTier={this.onRemoveUnsavedTier}
                    currentTiers={currentTiers}
                    defaultActive
                  />
                )}
              </div>
              <Button onClick={this.addTier}>Add tier</Button>
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}

export default TierPanel;

import React from 'react';
import FeatureRow from './FeatureRow';
import styles from './styles.module.scss';

const Package = ({ name, features, orgFlags, onCheckboxClick, description }) => {
  const flags = Object.values(orgFlags);
  const hasFlag = (flag) => flags.includes(flag);

  return (
    <div className={styles.package}>
      <div className={styles.package_heading}>{name}</div>
      <div className={styles.package_description}>{description}</div>

      {features.map(({ label, disabled, flags: flagsToSet }) => (
        <FeatureRow
          key={label}
          label={label}
          disabled={disabled}
          flags={flagsToSet}
          checked={flagsToSet.every(hasFlag)}
          onCheckboxClick={onCheckboxClick}
        />
      ))}
    </div>
  );
};

export default Package;

import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Checkbox } from '@hologram-dimension/checkbox';
import { getFormattedTiers } from 'common/selectors/entities/plans';
import * as carrierActions from 'common/store/entities/carriers/actions';
import * as groupActions from 'common/store/entities/groups/actions';
import * as offersActions from 'common/store/entities/offers/actions';
import * as orgActions from 'common/store/entities/orgs/actions';
import * as planActions from 'common/store/entities/plans/actions';
import * as tierActions from 'common/store/entities/tiers/actions';
import * as modalActions from 'common/store/ui/modals/actions';
import { countDecimalDigits } from 'common/utils/decimalCounter';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { modalNames } from 'components/modals/ModalInstances';
import EditingOrgPanel from 'components/plans/EditingOrgPanel';
import PlanPanel from 'components/plans/PlanPanel';
import TierPanel from 'components/plans/TierPanel';
import { difference, isEmpty, isNumber, omit } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';

const EditPlan = (props) => {
  const {
    addOrgToGroup,
    formattedTier,
    getCarriers,
    getOffers,
    getOrgInfo,
    getPlan,
    getRegions,
    getTierVolumeTypes,
    groupData,
    isPlanLoaded,
    loadedCarriers,
    loadedOffers,
    plan,
    regions,
    removeOrgFromGroup,
    updatePlan,
    updatePlanTiers,
    match: {
      params: { id },
    },
  } = props;

  const [addedOrgs, setAddedOrgs] = useState([]);
  const [removedOrgs, setRemovedOrgs] = useState([]);
  const [updatedPlanName, setUpdatedPlanName] = useState(null);
  const [updatedRegion, setUpdatedRegion] = useState(null);
  const [updatedDisplayCategory, setUpdatedDisplayCategory] = useState(null);
  const [updatedPlanBytes, setUpdatedPlanBytes] = useState(null);
  const [editedZone, setEditedZone] = useState({});
  const [currentTiers, setCurrentTiers] = useState({});
  const [acceptedRisk, setAcceptedRisk] = useState(false);
  const [editShowOrgs, setEditShowOrgs] = useState(false);

  useEffect(() => {
    getPlan(id);
    getCarriers();
    getOffers();
    getRegions();
    getTierVolumeTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentTiers(formattedTier);
  }, [formattedTier]);

  const addOrg = useCallback(
    (orgId) => {
      const addedOrgId = isNumber(orgId) ? orgId : parseInt(orgId, 10);

      setRemovedOrgs((prevRemovedOrgs) =>
        prevRemovedOrgs.filter((searchOrgId) => searchOrgId !== addedOrgId)
      );
      setAddedOrgs((prevAddedOrgs) => {
        if (prevAddedOrgs.find((searchOrgId) => searchOrgId === addedOrgId)) {
          return prevAddedOrgs;
        }
        getOrgInfo(addedOrgId);
        return [...prevAddedOrgs, addedOrgId];
      });
    },
    [getOrgInfo]
  );

  const removeOrg = useCallback((orgId) => {
    const removedOrgId = isNumber(orgId) ? orgId : parseInt(orgId, 10);

    setAddedOrgs((prevAddedOrgs) =>
      prevAddedOrgs.filter((searchOrgId) => searchOrgId !== removedOrgId)
    );

    setRemovedOrgs((prevRemovedOrgs) => {
      if (prevRemovedOrgs.find((searchOrgId) => searchOrgId === removedOrgId)) {
        return prevRemovedOrgs;
      }
      return [...prevRemovedOrgs, removedOrgId];
    });
  }, []);

  const onUpdatePlan = (property, value) => {
    if (property === 'name') {
      setUpdatedPlanName(value);
    } else if (property === 'region') {
      setUpdatedRegion(value);
    } else if (property === 'display_category') {
      setUpdatedDisplayCategory(value);
    } else {
      setUpdatedPlanBytes(value);
    }
  };

  const onUpdateZone = (property, value) => {
    setEditedZone({ ...editedZone, [property]: value });
  };

  const onUpdateCSP = (carrierId, value) => {
    setEditedZone((prevState) => ({
      ...prevState,
      carriers: {
        ...prevState.carriers,
        [carrierId]: {
          ...prevState.carriers[carrierId],
          csp: value,
        },
      },
    }));
  };

  const addTier = (tierInfo) => {
    const newTier = { [tierInfo.tier]: tierInfo };
    setCurrentTiers((prevTiers) => ({ ...prevTiers, ...newTier }));
  };

  const removeTier = (tierId) => {
    setCurrentTiers((prevTiers) => omit(prevTiers, tierId));
  };

  const updateTier = (tierId, tierValues) => {
    setCurrentTiers((prevTiers) => ({ ...omit(prevTiers, tierId), [tierValues.tier]: tierValues }));
  };

  const getZoneCarrierUpdates = () => {
    if (isEmpty(editedZone)) {
      return null;
    }

    const { zones } = plan.tiers.BASE;
    const [existingZoneName, existingZoneData] = Object.entries(zones)[0];
    const zoneName = editedZone.type ?? existingZoneName;
    return {
      [zoneName]: {
        ...existingZoneData,
        ...editedZone,
      },
    };
  };

  const persistPlanUpdate = () => {
    updatePlan(
      id,
      updatedPlanName,
      updatedPlanBytes,
      updatedRegion,
      getZoneCarrierUpdates(),
      updatedDisplayCategory
    );

    updatePlanTiers(id, currentTiers, formattedTier);
    if (addedOrgs) {
      addedOrgs.forEach((orgId) => {
        addOrgToGroup(plan.groupid, orgId);
      });
    }
    if (removedOrgs) {
      removedOrgs.forEach((orgId) => {
        removeOrgFromGroup(plan.groupid, orgId);
      });
    }
  };

  const showCompleteModal = () => {
    const { openModal } = props;

    openModal(modalNames.confirmation, {
      title: 'Confirm Edits',
      text: 'Are you sure you want to make these edits to this plan?',
      onConfirm: persistPlanUpdate,
    });
  };

  const isValidEdit = useMemo(() => {
    if (
      editedZone &&
      Object.values(editedZone).some((input) => input === '' || countDecimalDigits(input) > 6)
    ) {
      return false;
    }

    const existingOrgIds = !plan?.groupid
      ? []
      : groupData[plan.groupid]?.users?.map((member) => String(member.id));
    const orgEdit = difference(existingOrgIds, removedOrgs);
    if (!orgEdit?.length && !addedOrgs?.length) {
      return false;
    }

    const planCategory = updatedDisplayCategory ?? plan?.display_category;
    const region = updatedRegion ?? plan?.cellular_coverage_region?.id;

    if ((planCategory === 'standard' && !region) || region === '0') {
      return false;
    }

    return !!(
      addedOrgs?.length ||
      removedOrgs?.length ||
      updatedPlanName ||
      updatedPlanBytes ||
      updatedRegion ||
      updatedDisplayCategory ||
      Object.keys(editedZone ?? {}).length ||
      formattedTier !== currentTiers
    );
  }, [
    addedOrgs?.length,
    currentTiers,
    editedZone,
    formattedTier,
    groupData,
    plan?.groupid,
    plan?.display_category,
    plan?.cellular_coverage_region?.id,
    removedOrgs,
    updatedDisplayCategory,
    updatedPlanBytes,
    updatedPlanName,
    updatedRegion,
  ]);

  const formatPlan = () => {
    const { zones } = plan.tiers.BASE;
    const [zoneName, zoneData] = Object.entries(zones)[0];

    if (plan) {
      const region = plan?.cellular_coverage_region?.id
        ? `${plan.cellular_coverage_region.id}`
        : '';
      return {
        name: updatedPlanName ?? plan.name,
        description: plan.description,
        data: updatedPlanBytes ?? plan.data,
        region: updatedRegion ?? region,
        display_category: updatedDisplayCategory ?? plan.display_category,
        zones: {
          type: editedZone.type ?? zoneName,
          amount: editedZone.amount ?? zoneData.amount,
          sms: editedZone.sms ?? zoneData.sms,
          overage: editedZone.overage ?? zoneData.overage,
          carriers: editedZone.carriers ?? zoneData.carriers,
        },
      };
    }

    return undefined;
  };

  const toggleEditShowOrgs = useCallback(() => {
    setEditShowOrgs((last) => !last);
  }, []);

  if (
    !(
      isPlanLoaded &&
      Object.keys(loadedCarriers ?? {}).length &&
      Object.keys(loadedOffers ?? {}).length &&
      groupData[plan.groupid]?.users
    )
  ) {
    return (
      <div style={{ position: 'absolute', left: '50%' }}>
        <h3>Loading...</h3>
      </div>
    );
  }

  const group = groupData[plan.groupid];
  const hasMultipleZones = Object.values(plan.tiers).some(
    (tier) => Object.keys(tier.zones ?? {}).length > 1
  );
  const userCount = group?.users?.length ?? 0;
  const warningText = userCount > 3 ? `This plan affects ${userCount} organizations!` : '';

  return (
    <ErrorBoundary>
      <div className={styles.container}>
        {isValidEdit && (
          <BannerNotification variant="reminder">There are unsaved changes</BannerNotification>
        )}
        {warningText && <BannerNotification variant="caution">{warningText}</BannerNotification>}
        {hasMultipleZones && (
          <BannerNotification variant="caution">
            This plan has multiple zones per tier that will not be reflected on this page.
          </BannerNotification>
        )}
        <h2 className={styles.planHeader}>
          {plan.name} ({plan.id})
        </h2>
        <Button
          style={{ maxWidth: 400 }}
          variant={editShowOrgs ? 'secondary' : 'primary'}
          onClick={toggleEditShowOrgs}
        >
          {editShowOrgs ? 'Hide organizations' : 'Show/edit organizations'}
        </Button>
        {editShowOrgs && (
          <EditingOrgPanel
            groupid={plan.groupid}
            addOrg={addOrg}
            removeOrg={removeOrg}
            addedOrgs={addedOrgs}
            removedOrgs={removedOrgs}
            isEditPage
            header={`Edit group ${group.name} (${group.id})`}
          />
        )}
        <PlanPanel
          planData={formatPlan()}
          isEditPage
          onUpdatePlan={onUpdatePlan}
          onUpdateZone={onUpdateZone}
          onUpdateCSP={onUpdateCSP}
          regions={regions}
        />
        <TierPanel
          tiers={currentTiers}
          onAddTier={addTier}
          updateTiers={updateTier}
          removeTier={removeTier}
          isEditPage
        />
        <div className={styles.bottomWell}>
          {isValidEdit && (
            <BannerNotification variant="reminder">There are unsaved changes</BannerNotification>
          )}
          {warningText && (
            <>
              <BannerNotification variant="caution">{warningText}</BannerNotification>

              <Checkbox
                label="Acknowledged"
                value={acceptedRisk}
                onChange={() => setAcceptedRisk((prevAcceptedRisk) => !prevAcceptedRisk)}
                disabled={!isValidEdit}
                className={styles.acknowledgeCheck}
              />
            </>
          )}
          <Button
            className={styles.saveButton}
            onClick={showCompleteModal}
            disabled={!isValidEdit || !!(warningText && !acceptedRisk)}
          >
            Save
          </Button>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  return {
    plan: state.entities.plans.byId[id],
    isPlanLoaded: state.entities.plans.loaded[id],
    formattedTier: getFormattedTiers(state, props),
    loadedCarriers: state.entities.carriers.byId,
    loadedOffers: state.entities.offers.byId,
    groupData: state.entities.groups.byId,
    isGroupLoaded: state.entities.groups.loaded,
    regions: state.entities.plans.regions,
  };
};

const mapDispatchToProps = {
  addOrgToGroup: groupActions.addOrgToGroup,
  getCarriers: carrierActions.getCarriers,
  getOffers: offersActions.getOffers,
  getOrgInfo: orgActions.getOrgInfo,
  getPlan: planActions.getPlan,
  getRegions: planActions.getRegions,
  getTierVolumeTypes: tierActions.getTierVolumeTypes,
  openModal: modalActions.openModal,
  removeOrgFromGroup: groupActions.removeOrgFromGroup,
  updatePlan: planActions.updatePlan,
  updatePlanTiers: tierActions.updatePlanTiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlan);

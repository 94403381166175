/* eslint-disable no-console */
import { BannerNotification } from '@hologram-dimension/banner-notification';
import { Button } from '@hologram-dimension/button';
import { Icon } from '@hologram-dimension/icon';
import React from 'react';

import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error({ type: 'Uncaught error in child', error, errorInfo });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div className={styles.errorPage}>
          <BannerNotification variant="error" defaultIcon className={styles.errorBanner}>
            {`An error occurred loading this page: ${error}`}
          </BannerNotification>
          <Button onClick={() => window.location.reload()} iconStart={<Icon name="Sync" />}>
            Refresh
          </Button>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

import React from 'react';
import { Button, Panel } from '@holokit/core';
import { connect } from 'react-redux';
import classnames from 'classnames';

import PlanDetails from 'components/plans/PlanPanel/DetailsPanel';
import * as planActionTypes from 'common/store/entities/plans/actionTypes';
import styles from './styles.module.scss';

const CreatePlanConfirmation = (props) => {
  const {
    closeModal,
    createError,
    groupName,
    onYes,
    orgId,
    orgName,
    planCreateLoading,
    planDetails,
  } = props;

  return (
    <Panel
      title="Create Plan?"
      noBodyPadding
      classes={styles.panel}
      isLoading={planCreateLoading}
      type={classnames({ warn: createError })}
    >
      <div className={styles.modalContainer}>
        {!createError && (
          <>
            <PlanDetails
              orgName={orgName}
              orgId={orgId}
              groupName={groupName}
              planData={planDetails}
            />
            <div className={styles.footer}>
              <Button type="destructiveMinor" onClick={closeModal}>
                Go Back
              </Button>
              <Button type="primary" onClick={onYes}>
                Create
              </Button>
            </div>
          </>
        )}
        {createError && (
          <>
            There was an error trying to create this plan. This likely means that some state has
            been left beind (a group was created, the org was added to it, etc) that should be
            cleaned up.
            <div className={styles.stacktrace}>
              <h2>Stack trace:</h2>
              {createError}
            </div>
            <div className={styles.footer}>
              <div />
              <Button type="primary" onClick={closeModal}>
                Close
              </Button>
            </div>
          </>
        )}
      </div>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  planCreateLoading: state.ui.loading[planActionTypes.PERSIST_PLAN_REQUEST],
  createError: state.state.plans.createPlan?.error,
});

export default connect(mapStateToProps)(CreatePlanConfirmation);

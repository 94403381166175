import { Button } from '@hologram-dimension/button';
import * as carrierActions from 'common/store/entities/carriers/actions';
import * as groupActions from 'common/store/entities/groups/actions';
import * as offersActions from 'common/store/entities/offers/actions';
import * as orgActions from 'common/store/entities/orgs/actions';
import * as planActions from 'common/store/entities/plans/actions';
import * as tierActions from 'common/store/entities/tiers/actions';
import * as uiActions from 'common/store/ui/entities/actions';
import * as modalActions from 'common/store/ui/modals/actions';
import { countDecimalDigits } from 'common/utils/decimalCounter';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { modalNames } from 'components/modals/ModalInstances';
import CreatingOrgPanel from 'components/plans/CreatingOrgPanel';
import GroupPanel from 'components/plans/GroupPanel';
import PlanPanel from 'components/plans/PlanPanel';
import TierPanel from 'components/plans/TierPanel';
import { isEmpty, omit } from 'lodash';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';

import styles from './styles.module.scss';

const SCROLL_PARAMS = {
  behavior: 'smooth',
  block: 'center',
};

const STEP_ORDER = ['group', 'addOrg', 'planParams', 'tiers'];

const initialState = {
  isTouched: false,
  activeStep: 'group',
  group: {
    isNew: null,
    name: null,
    id: null,
  },
  orgId: null,
  orgName: null,
  plan: {
    name: '',
    description: '',
    data: '',
    region: '',
    zones: {
      type: '',
      amount: '',
      sms: '',
      overage: '',
      carriers: {},
    },
  },
  tiers: {},
};

class CreatePlan extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.orgPanel = createRef();
    this.plansPanel = createRef();
    props.getCarriers();
    props.getOffers();
    props.getTierVolumeTypes();
    props.getRegions();
  }

  componentWillUnmount() {
    const { clearActiveGroup, clearGroupSearchResults, clearOrgSearchResults } = this.props;
    clearGroupSearchResults();
    clearOrgSearchResults();
    clearActiveGroup();
  }

  onGroupComplete = (isNew, name, groupId) => {
    this.setState({
      isTouched: true,
      group: {
        isNew,
        name,
        id: groupId,
      },
      activeStep: 'addOrg',
    });

    if (this.orgPanel.current) {
      this.orgPanel.current.scrollIntoView(SCROLL_PARAMS);
    }
  };

  onUpdateZone = (fieldName, fieldValue) => {
    this.setState((prevState) => ({
      isTouched: true,
      plan: {
        ...prevState.plan,
        zones: {
          ...prevState.plan.zones,
          [fieldName]: fieldValue,
        },
      },
    }));
  };

  onUpdateCSP = (carrierId, csp) => {
    this.setState((prevState) => ({
      plan: {
        ...prevState.plan,
        zones: {
          ...prevState.plan.zones,
          carriers: {
            ...prevState.plan.zones.carriers,
            [carrierId]: {
              ...prevState.plan.zones.carriers[carrierId],
              csp,
            },
          },
        },
      },
    }));
  };

  onOrgComplete = (orgId, orgName) => {
    this.setState({
      isTouched: true,
      orgId,
      orgName,
      activeStep: 'planParams',
    });
    if (this.plansPanel.current) {
      this.plansPanel.current.scrollIntoView(SCROLL_PARAMS);
    }
  };

  onUpdatePlan = (fieldName, fieldValue) => {
    if (fieldName === 'name') {
      this.setState((prevState) => ({
        isTouched: true,
        plan: {
          ...prevState.plan,
          name: fieldValue,
          description: fieldValue,
        },
      }));
    } else {
      this.setState((prevState) => ({
        isTouched: true,
        plan: {
          ...prevState.plan,
          [fieldName]: fieldValue,
        },
      }));
    }
  };

  onAddTier = (tier) => {
    this.setState((prevState) => ({
      isTouched: true,
      tiers: {
        ...prevState.tiers,
        [tier.tier]: {
          tier: tier.tier,
          amount: tier.amount,
          sms: tier.sms,
          overage: tier.overage,
        },
      },
    }));
  };

  removeOrg = () => {
    this.setState({ orgId: null, orgName: null });
  };

  createPlan = () => {
    const { group, orgId, plan, tiers } = this.state;
    const { createPlan } = this.props;
    createPlan(group, orgId, plan, tiers);
    this.setState({ ...initialState });
  };

  showCompleteModal = () => {
    const { openModal } = this.props;
    const { group, orgId, orgName, plan } = this.state;

    openModal(modalNames.createPlanConfirmation, {
      orgName,
      orgId,
      groupName: group.name,
      planDetails: plan,
      onYes: this.createPlan,
    });
  };

  setPlanZone = (zone) => {
    this.setState((prevState) => ({
      isTouched: true,
      plan: {
        ...prevState.plan,
        zones: {
          ...prevState.plan.zones,
          [zone.type]: zone,
        },
      },
    }));
  };

  updateTiers = (tierValue, values) => {
    const { tiers } = this.state;
    const updatedTiers = omit(tiers, tierValue);
    this.setState({
      tiers: {
        ...updatedTiers,
        [values.tier]: { ...values },
      },
    });
  };

  removeTier = (tierValue) => {
    this.setState((prevState) => ({
      tiers: omit(prevState.tiers, tierValue),
    }));
  };

  planParamsAreValid = () => {
    const { plan } = this.state;
    return (
      plan.name &&
      plan.data &&
      plan.zones.type &&
      countDecimalDigits(plan.zones.amount) <= 6 &&
      countDecimalDigits(plan.zones.sms) <= 6 &&
      countDecimalDigits(plan.zones.overage) <= 6
    );
  };

  carriersValid = () => {
    const { plan } = this.state;
    const { carriers } = plan.zones;
    const isValid = Object.values(carriers).every(({ csp, apn }) => apn && csp);
    // make sure values are good and we have them at all
    return isValid && !isEmpty(carriers);
  };

  tiersValid = () => {
    const { tiers } = this.state;
    const tierValues = Object.values(tiers);
    if (tierValues.length === 0) {
      return true;
    }

    return tierValues.every((tier) => tier.tier && tier.amount && tier.sms && tier.overage);
  };

  planIsValid = () => {
    const { group, orgId } = this.state;
    const { selectedGroup } = this.props;

    const groupIsValid = (group.isNew && group.name) || (!group.isNew && group.id);
    const orgIsValid = group.isNew ? !!orgId : !!group.id && selectedGroup?.users?.length;
    const planIsValid = this.planParamsAreValid();
    const carriersAreValid = this.carriersValid();
    const tiersAreValid = this.tiersValid();
    return groupIsValid && orgIsValid && planIsValid && carriersAreValid && tiersAreValid;
  };

  render() {
    const { selectedGroup, regions } = this.props;
    const { activeStep, group, orgId, orgName, plan, tiers, isTouched } = this.state;

    const currentIndex = STEP_ORDER.indexOf(activeStep);
    const groupsAreDone = currentIndex > 0;
    const orgIsDone = currentIndex > 1;

    return (
      <ErrorBoundary>
        <div className={styles.container}>
          <Prompt
            when={isTouched}
            message="You have data on this page. If you leave it might not still be here."
          />
          <GroupPanel
            onComplete={this.onGroupComplete}
            type={groupsAreDone ? 'success' : null}
            orgName={orgName}
            removeOrg={this.removeOrg}
          />
          <CreatingOrgPanel
            groupIsNew={group.isNew}
            groupName={group.name}
            isActive={activeStep === 'addOrg'}
            isComplete={orgIsDone}
            onOrgSelect={this.onOrgComplete}
            panelRef={this.orgPanel}
            group={selectedGroup}
          />
          <div ref={this.plansPanel}>
            <PlanPanel
              group={selectedGroup ?? group}
              onUpdatePlan={this.onUpdatePlan}
              onUpdateZone={this.onUpdateZone}
              onUpdateCSP={this.onUpdateCSP}
              orgId={orgId}
              orgName={orgName}
              planData={plan}
              setPlanZone={this.setPlanZone}
              regions={regions}
            />
          </div>
          <TierPanel
            onAddTier={this.onAddTier}
            tiers={tiers}
            updateTiers={this.updateTiers}
            removeTier={this.removeTier}
          />
          <Button
            className={styles.createButton}
            onClick={this.showCompleteModal}
            disabled={!this.planIsValid()}
          >
            Create Plan
          </Button>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedGroupId = state.ui.entities.selections.activeGroup;
  const selectedGroup = state.entities.groups.byId[selectedGroupId];

  return {
    selectedGroup,
    regions: state.entities.plans.regions,
  };
};

const mapDispatchToProps = {
  clearActiveGroup: uiActions.unsetActiveGroupId,
  clearGroupSearchResults: groupActions.clearSearchResults,
  clearOrgSearchResults: orgActions.clearSearchResults,
  createPlan: planActions.createPlan,
  getCarriers: carrierActions.getCarriers,
  getOffers: offersActions.getOffers,
  getRegions: planActions.getRegions,
  getTierVolumeTypes: tierActions.getTierVolumeTypes,
  openModal: modalActions.openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlan);

import React, { useState } from 'react';
import { Icon, Panel } from '@holokit/core';
import { useSelector } from 'react-redux';
import { selectOrgPackage } from 'common/selectors/entities/orgs';
import useCallouts from './useCallouts';
import Features from './Features';
import PlanSelect from './PlanSelect';
import ResponseCallout from './ResponseCallout';
import featureFlagList from './FeatureFlagList';
import styles from './styles.module.scss';

const ResponseCallouts = ({ callouts, removeCallout }) => (
  <div className={styles.response_callout_container}>
    {callouts.map(({ text, calloutType, uuid }) => (
      <ResponseCallout
        key={uuid}
        text={text}
        calloutType={calloutType}
        onClick={() => removeCallout(uuid)}
      />
    ))}
  </div>
);

const PackagesPanel = ({ openModal, orgInfo, setReleaseFlagsLoading }) => {
  const [loading, setLoading] = useState(false);
  const { callouts, addSuccessCallouts, addErrorCallouts, removeCallout } = useCallouts();

  const { id: orgId, releaseFlags: orgFlags } = orgInfo;
  const orgPackage = useSelector(selectOrgPackage(orgId));
  const packageId = orgPackage?.package_id ?? false;

  const configureFeatureFlagList = Object.entries(featureFlagList).reduce((acc, [key, val]) => {
    const flagProperties = val.map((item) => ({ ...item, disabled: !packageId }));
    const configuredEntry = { [key]: flagProperties };
    return { ...acc, ...configuredEntry };
  }, {});

  if (!orgFlags) return null;

  return (
    <Panel
      title="Plans and add-ons"
      className={styles.packages_panel_container}
      icon={Icon.names.PROJECT}
      iconSize={Icon.sizes.MINOR}
      iconSvgProps={{ fill: '#6606C6' }}
      isLoading={loading}
    >
      <PlanSelect orgId={orgId} openModal={openModal} setPanelLoading={setLoading} />

      <Features
        featureFlagList={configureFeatureFlagList}
        orgFlags={orgFlags}
        orgId={orgId}
        setPanelLoading={setLoading}
        addSuccessCallouts={addSuccessCallouts}
        addErrorCallouts={addErrorCallouts}
        setReleaseFlagsLoading={setReleaseFlagsLoading}
        preflightTimeout={orgInfo.preflight_timeout_in_days}
      />

      <ResponseCallouts callouts={callouts} removeCallout={removeCallout} />
    </Panel>
  );
};

export default PackagesPanel;

import { Button } from '@hologram-dimension/button';
import { Panel } from '@hologram-dimension/panel';
import { pick } from 'lodash';
import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import SearchOrgsForm from '../SearchOrgsForm';

import styles from './EditingOrgPanel.module.scss';

const OrgRow = memo(
  ({ org, onRemoveOrg }) => {
    const onRemoveOrgClick = useCallback(
      (orgId) => {
        onRemoveOrg(orgId);
      },
      [onRemoveOrg]
    );

    return (
      <tr key={org.id}>
        <td className={styles.form__results__item}>
          {org.name} ({org.id})
        </td>
        <td>
          <Button variant="delete" size="small" onClick={() => onRemoveOrgClick(org.id)}>
            Remove
          </Button>
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) => prevProps.org.id === nextProps.org.id
);

const EditingOrgPanel = memo((props) => {
  const {
    group,
    isEditPage,
    addOrg,
    removeOrg,
    removedOrgs,
    addedOrgsObjects,
    header = 'Orgs',
  } = props;

  const filteredOrgMembers =
    (group.users ?? []).filter((org) => !removedOrgs.includes(org.id)) ?? [];

  const displayOrgMembers = [...filteredOrgMembers, ...Object.values(addedOrgsObjects)];

  if (group.users) {
    return (
      <Panel header={header}>
        <div className={styles.panelContainer}>
          <SearchOrgsForm
            groupMembers={displayOrgMembers}
            isEditPage={isEditPage}
            group={group}
            addOrg={addOrg}
            header="Add orgs"
          />
          <div className={styles.orgResults}>
            <div>
              <div className={styles.orgTableWrapper}>
                <table className={styles.orgTable}>
                  <tbody>
                    {displayOrgMembers.map((org) => (
                      <OrgRow key={org.id} org={org} onRemoveOrg={removeOrg} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
  return (
    <div style={{ position: 'relative', left: '50%' }}>
      <h1>Loading...</h1>
    </div>
  );
});

const mapStateToProps = (state, props) => {
  const { groupid, addedOrgs } = props;
  const group = state.entities.groups.byId[groupid];
  const addedOrgsObjects = pick(state.entities.orgs.byId, addedOrgs) ?? {};
  return {
    group,
    searchData: state.entities.orgs.temp,
    addedOrgsObjects,
  };
};

export default connect(mapStateToProps)(EditingOrgPanel);

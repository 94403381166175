import React, { useState, useCallback, useMemo } from 'react';
import { FormField } from '@hologram-dimension/form-field';
import { Radio } from '@hologram-dimension/radio';
import { TextInput } from '@hologram-dimension/text-input';
import debounce from 'lodash.debounce';
import styles from './styles.module.scss';

function processDuration(orgFlags, preflightTimeout) {
  const flags = Object.values(orgFlags);
  if (!flags.includes('preflight')) {
    return 'none';
  }
  const duration = preflightTimeout?.toString();

  if (!duration) {
    return 'custom';
  }
  return ['90', '365', '36500'].includes(duration) ? duration : 'custom';
}

const Preflight = ({
  preflightFlags,
  orgFlags,
  onFlagChange,
  onPreflightDurationChange,
  preflightTimeout,
}) => {
  const [values, setValues] = useState({
    duration: processDuration(orgFlags, preflightTimeout),
    custom: preflightTimeout,
  });
  const features = preflightFlags;

  const onChange = useCallback(
    async (e) => {
      const { name, value } = e.target;
      setValues((prevValues) => ({ ...prevValues, [name]: value }));

      if (value === 'none') {
        features.forEach(async ({ label, flags: flagsToSet }) => {
          await onFlagChange({ label, flags: flagsToSet, disable: true });
        });
      } else if (value !== 'custom') {
        features.forEach(async ({ label, flags: flagsToSet }) => {
          await onFlagChange({ label, flags: flagsToSet, disable: false });
        });
        const duration = parseInt(value, 10);
        await onPreflightDurationChange({ duration });
      }
    },
    [features, onFlagChange, onPreflightDurationChange]
  );

  const submitCustomChange = useCallback(
    (duration) => {
      features.forEach(({ label, flags: flagsToSet }) => {
        onFlagChange({ label, flags: flagsToSet, disable: false });
      });
      onPreflightDurationChange({ duration });
    },
    [features, onFlagChange, onPreflightDurationChange]
  );

  const debouncedCustomChange = useMemo(
    () => debounce(submitCustomChange, 500),
    [submitCustomChange]
  );

  const onCustomChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
      debouncedCustomChange(parseInt(value, 10));
    },
    [debouncedCustomChange]
  );

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();
  };

  return (
    <FormField
      layoutDirection="column"
      role="radiogroup"
      label="Pre-deployment testing duration (Advanced, Premium)"
    >
      {({ ariaDescribedbyIds }) => (
        <>
          <div className={styles.package_description}>
            Set the testing duration for new SIMs added to this organization
          </div>

          <Radio
            ariaDescribedby={ariaDescribedbyIds}
            className={styles.preflight_radio}
            defaultChecked={values.duration === 'none'}
            disabled={false}
            label="None"
            name="duration"
            value="none"
            onChange={onChange}
          />
          <Radio
            ariaDescribedby={ariaDescribedbyIds}
            className={styles.preflight_radio}
            defaultChecked={values.duration === '90'}
            disabled={false}
            label="3 months (90 days)"
            name="duration"
            value="90"
            onChange={onChange}
          />
          <Radio
            ariaDescribedby={ariaDescribedbyIds}
            className={styles.preflight_radio}
            defaultChecked={values.duration === '365'}
            disabled={false}
            label="12 months (365 days)"
            name="duration"
            value="365"
            onChange={onChange}
          />
          <Radio
            ariaDescribedby={ariaDescribedbyIds}
            className={styles.preflight_radio}
            defaultChecked={values.duration === 'custom'}
            disabled={false}
            label="Custom"
            name="duration"
            value="custom"
            onChange={onChange}
          />
          {values.duration === 'custom' ? (
            <div className={styles.preflight_custom_text_days}>
              <TextInput
                ariaDescribedby={ariaDescribedbyIds}
                className={styles.preflight_custom_text}
                id="preflight-custom-duration-text"
                onChange={onCustomChange}
                name="customDuration"
                type="number"
                defaultValue={values.custom}
                onWheel={numberInputOnWheelPreventChange}
              />
              days
            </div>
          ) : null}
          <Radio
            ariaDescribedby={ariaDescribedbyIds}
            className={styles.preflight_radio}
            defaultChecked={values.duration === '36500'}
            disabled={false}
            label="Unlimited"
            name="duration"
            value="36500"
            onChange={onChange}
          />
        </>
      )}
    </FormField>
  );
};

export default Preflight;

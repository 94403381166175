import React, { useState } from 'react';
import { Link } from 'react-router';
import { Button } from '@holokit/core';
import TierTable from '../TierTable';

import styles from './styles.module.scss';

export default (props) => {
  const {
    result,
    result: { tiers },
  } = props;

  const [buttonDisplay, setButtonDisplay] = useState(false);

  const sortedTiers = Object.entries(tiers).sort(([a], [b]) => {
    if (a === 'BASE') {
      return -1;
    }
    if (b === 'BASE') {
      return 1;
    }
    return parseInt(a) > parseInt(b);
  });

  return (
    <tr
      className={styles.TableRow}
      onMouseEnter={() => setButtonDisplay(true)}
      onMouseLeave={() => setButtonDisplay(false)}
    >
      <td className={styles.TableCellTextContentCell}>{result.id}</td>

      <td className={styles.TableCellTextContentCell}>{result.name}</td>

      <td className={styles.TableCellTextContentCell}>{result.groupid}</td>

      <td className={styles.TableCellTextContentCell}>
        {Math.round(result.data / 1000000).toString()}
      </td>

      <td className={styles.TableCell} colSpan="6">
        <TierTable sortedTiers={sortedTiers} />
      </td>

      <td className={styles.TableCell}>
        {buttonDisplay && (
          <Link to={`/workspace/plans/${result.id}/edit`}>
            <Button type="secondary" iconLeft="Pencil" small />
          </Link>
        )}
      </td>
    </tr>
  );
};

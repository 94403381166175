import { Button } from '@hologram-dimension/button';
import { InlineNotification } from '@hologram-dimension/inline-notification';
import { SelectField } from '@hologram-dimension/select-field';
import { CARRIER_DEFAULTS } from 'common/utils/constants';
import { isEqual, pickBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styles from './CarrierForm.module.scss';

const CARRIER_PRESET_OPTIONS = [
  {
    value: '',
    label: '',
  },
  ...Object.entries(CARRIER_DEFAULTS).map(([carrierPreset, carrierData]) => ({
    value: carrierPreset,
    label: carrierData.displayName,
  })),
];

class CarrierForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preset: '',
      isPresetEdited: false,
      isCspEdited: false,
      canEditCSPs: false,
    };
  }

  setCarrierPreset = (e) => {
    const { updateZone } = this.props;
    const { value } = e.target;
    if (!value || value === 'none') {
      return;
    }
    const { carriers } = CARRIER_DEFAULTS[value];

    updateZone('carriers', carriers);
    this.setState({ preset: value, isPresetEdited: true });
  };

  buildOptions = (carrierId) => {
    const { loadedOffers } = this.props;
    const carrierOffers = Object.values(loadedOffers).filter(
      (offer) => offer.carrierid.toString() === carrierId
    );
    return carrierOffers.map((offer) => offer.csp);
  };

  allowEditing = () => {
    this.setState({ canEditCSPs: true });
  };

  findDisplayValue = () => {
    const { carriers } = this.props;

    const existingPreset = pickBy(CARRIER_DEFAULTS, (carrier) =>
      isEqual(carrier.carriers, carriers)
    );

    return Object.keys(existingPreset ?? {})[0];
  };

  updateCarrier = (e, carrierId) => {
    const { onUpdateCSP } = this.props;
    this.setState({ isCspEdited: true });

    onUpdateCSP(carrierId, e.target.value);
  };

  render() {
    const { preset, isPresetEdited, canEditCSPs, isCspEdited } = this.state;
    const { carriers, loadedCarriers, isEditPage } = this.props;
    const displayValue =
      isEditPage && !isPresetEdited && !isCspEdited ? this.findDisplayValue() : preset;

    return (
      <div className={styles.container}>
        <div className={styles.input}>
          {isCspEdited && isEditPage && <span className={styles.changedPlan}>* Changes Made</span>}
          <SelectField
            label="Carrier preset"
            defaultValue={displayValue ?? 'none'}
            name="id"
            options={CARRIER_PRESET_OPTIONS}
            onChange={this.setCarrierPreset}
            disabled={isEditPage}
          />
        </div>
        {!isEditPage && (
          <>
            <InlineNotification variant={canEditCSPs ? 'caution' : 'note'}>
              Editing the CSP&apos;s that a plan offers can have unintended consequences, such as a
              device not being able to connect. Only update these if you know what the impact will
              be.
            </InlineNotification>
            <Button
              variant="destructive"
              size="small"
              onClick={this.allowEditing}
              className={styles.fullWidthButton}
              disabled={canEditCSPs}
            >
              Let me make changes
            </Button>
          </>
        )}
        {Object.entries(carriers).map(([carrierId, carrierData]) => (
          <div className={styles.carrierContainer} data-test="carriers-container" key={carrierId}>
            <span className={styles.subHeading}>{loadedCarriers[carrierId].name}</span>
            <SelectField
              label="CSP"
              defaultValue={carrierData.csp}
              layoutDirection="row"
              disabled={!canEditCSPs}
              options={this.buildOptions(carrierId)}
              onChange={(e) => this.updateCarrier(e, carrierId, carrierData)}
            />
            <div>APN: {carrierData.apn}</div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadedCarriers: state.entities.carriers.byId,
  loadedOffers: state.entities.offers.byId,
});

export default connect(mapStateToProps)(CarrierForm);

import { Button } from '@hologram-dimension/button';
import { Form } from '@hologram-dimension/form';
import { TextInputField } from '@hologram-dimension/text-input-field';
import { countDecimalDigits } from 'common/utils/decimalCounter';
import React from 'react';
import styles from './TierForm.module.scss';

class TierForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      tier: props.tierInfo?.tier ?? '',
      amount: props.tierInfo?.amount ?? '',
      sms: props.tierInfo?.sms ?? '',
      overage: props.tierInfo?.overage ?? '',
      isActive: props.defaultActive ?? false,
    };
  }

  onRemoveTier = () => {
    const { removeTier, tierInfo } = this.props;
    if (tierInfo) {
      removeTier(tierInfo.tier);
    } else {
      removeTier();
    }
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    let expression;

    // tier regex (whole numbers only) is different than cost regex (numbers with decimals)
    if (name === 'tier') {
      expression = /^[1-9]\d*$/;
    } else {
      expression = /^[0-9]\d*\.?\d*$/;
    }

    if (expression.test(value) || value === '') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleDone = () => {
    const { tier, amount, sms, overage } = this.state;
    const { onAddTier } = this.props;

    onAddTier({
      tier,
      amount,
      sms,
      overage,
    });
    this.setState({ isActive: false });
  };

  setActive = () => {
    this.setState({ isActive: true });
  };

  tierValueIsValid = () => {
    const { currentTiers, tierInfo } = this.props;
    const { tier } = this.state;

    if (!tierInfo) {
      return false;
    }

    const tierAlreadyExists = currentTiers.includes(tier) && tierInfo.tier !== parseInt(tier, 10);
    const tierIdUpdates = tier !== tierInfo?.tier;
    return tierAlreadyExists && tierIdUpdates;
  };

  formIsValid = () => {
    const { tier, amount, sms, overage } = this.state;

    const mrcValid = countDecimalDigits(amount) <= 6;
    const smsValid = countDecimalDigits(sms) <= 6;
    const overageValid = countDecimalDigits(overage) <= 6;

    return (
      tier &&
      amount &&
      sms &&
      overage &&
      !this.tierValueIsValid() &&
      mrcValid &&
      smsValid &&
      overageValid
    );
  };

  render() {
    const { tier, amount, sms, overage, isActive } = this.state;
    const tierIdInvalid = this.tierValueIsValid();

    const mrcValid = countDecimalDigits(amount) <= 6;
    const smsValid = countDecimalDigits(sms) <= 6;
    const overageValid = countDecimalDigits(overage) <= 6;

    return (
      <div className={styles.tier_card}>
        <Form
          header="Tier"
          footerActions={
            isActive ? (
              <>
                <Button variant="delete" onClick={this.onRemoveTier}>
                  Remove
                </Button>
                <Button onClick={this.handleDone} disabled={!this.formIsValid()}>
                  Done
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={this.setActive}>
                Edit
              </Button>
            )
          }
        >
          <h2 className={styles.subHeading}>Tier</h2>
          <TextInputField
            label="Device volume"
            placeholder="100"
            name="tier"
            defaultValue={tier}
            inputProps={{ onChange: this.handleChange }}
            invalid={tierIdInvalid}
            disabled={!isActive}
            type="text"
            required
          />
          <TextInputField
            label="MRC / Device"
            placeholder="1"
            name="amount"
            defaultValue={amount}
            inputProps={{ onChange: this.handleChange }}
            invalid={!mrcValid}
            disabled={!isActive}
            type="text"
            required
          />
          <TextInputField
            label="Cost / SMS"
            placeholder="0.19"
            name="sms"
            defaultValue={sms}
            inputProps={{ onChange: this.handleChange }}
            invalid={!smsValid}
            disabled={!isActive}
            type="text"
            validationMessage="Max six decimal places"
            required
          />
          <TextInputField
            label="Cost / overage MB"
            placeholder="0.25"
            name="overage"
            defaultValue={overage}
            inputProps={{ onChange: this.handleChange }}
            invalid={!overageValid}
            disabled={!isActive}
            type="text"
            validationMessage="Max six decimal places"
            required
          />
        </Form>
      </div>
    );
  }
}

export default TierForm;

import React from 'react';

import SearchPanel from 'components/plans/SearchPanel';

import styles from './styles.module.scss';

const SearchPlan = () => (
  <div className={styles.container}>
    <SearchPanel />
  </div>
);

export default SearchPlan;
